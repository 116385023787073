.aboutMain {
    background-color: black;
    position: relative;
    z-index: 0;
    color: white;
  }
  .aboutText {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    position: relative;
  }
  .aboutBG {
    color: white;
    position: relative;
    text-align: center;
  }
  
  .aboutBG > img {
    width: 100%;
    margin: 0 auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 60%;
  }
  .aboutTitle {
    font-size: 5rem;
    text-align: center;
    font-weight: bold;
    z-index: 1;
  }
  .aboutTitle > span {
    background: linear-gradient(to right, #ffffff, #f26021);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .aboutDesc {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    z-index: 2;
  }
  .aboutText > img {
    height: 2rem;
    width: 1.5rem;
  }
  .aboutText > p {
    color: white;
  }
  .aboutScroll {
    padding-top: 1.5rem;
    margin: 1.5rem;
    text-align: center;
    color: white;
    z-index: 2;
    cursor: pointer;
  }
  .aboutMid {
    color: white;
    position: relative;
    text-align: center;
    height: 30rem;
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
  
  .aboutMidBG > img {
    width: 80%;
    margin: 0 auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 10%;
    height: 30rem;
    z-index: 1;
    opacity: 90%;
  }
  .aboutLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .aboutLogo > aboutLine {
    height: 1rem;
  }
  .aboutMid > p {
    width: 60%;
    margin: 0 auto;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .aboutMid > .aboutLogo,
  .aboutMid > p {
    position: relative;
    top: 30%;
    z-index: 2;
  }
  .ourTeam {
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    text-align: center;
  }
  
  .ourTeamBG > img {
    width: 100%;
    margin: 0 auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 50%;
    height: 100%;
  }
  .ourTeamSub {
    width: 80%;
    margin: 0 auto;
    z-index: 2;
  }
  .ourTeamTitle {
    font-size: 5rem;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }
  .ourTeamTitle > p > span {
    background: linear-gradient(to right, #ffffff, #f26021);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .ourTeam1 {
    display: flex;
    height: 100%;
    text-align: center;
    z-index: 2;
  }
  .ourTeam2 {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    text-align: center;
    z-index: 2;
  }
  .ourTeam1Text {
    display: flex;
    flex-direction: column;
    flex: 6;
    z-index: 2;
  }
  .ourTeam2Text {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 6;
    z-index: 2;
  }
  .textName {
    font-size: 3rem;
    padding: 0;
    margin: 0;
    z-index: 2;
  }
  .textPosition {
    font-size: 1.5rem;
    color: #f26021;
    z-index: 2;
  }
  .textDesc {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    z-index: 2;
  }
  
  .ourTeam1Img1 {
    display: flex;
    height: 25rem;
    width: auto;
    flex: 4;
  }
  .ourTeam1Img1 > img {
    width: auto;
    height: 90%;
    margin: 0 auto;
  }
  .ourTeam1Img3 {
    display: flex;
    height: 25rem;
    width: auto;
    flex: 4;
  }
  .ourTeam1Img3 > img {
    width: auto;
    height: 90%;
    margin: 0 auto;
  }
  .ourTeam1Img2 {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .aboutDesc {
      width: 100%;
    }
    .aboutLogo {
      max-width: 100%;
    }
    .aboutLine {
      width: 20%;
    }
    .aboutMid > p {
      width: 100%;
    }
    .ourTeam1Img1 {
      display: none;
    }
    .ourTeam1Img2 {
      display: block;
    }
    .ourTeam1Img2 > img {
      height: 15rem;
      width: auto;
      margin-top: 2rem;
    }
    .ourTeam1Img3 {
      flex-direction: column;
      flex: 1;
    }
    .ourTeam1Img3 > img {
      height: 15rem;
      width: auto;
      margin-top: 2rem;
    }
    .textDesc {
      width: 90%;
    }
    .ourTeam2Text{
     flex-direction: column;
    }
  }