nav{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 50%;
    padding-left: 3rem;
    padding-top: 1rem;
}
nav > a{
    color: azure;
    font-weight: 700;
}
body{
    background-image: url("../public/aboutBG.png");
    background-position: center;
    /* background-blend-mode: hard-light; */
    background-size: cover;
    overflow-x: hidden;
}
.okp{
    text-decoration: none;
}
a{
    text-decoration: none;
    color: inherit;
}
nav>a:hover{
    text-decoration: underline;
    text-decoration-color: #F26021;
    text-underline-offset: 1rem;
    cursor: pointer;


}
.main-2{
    display: flex;
    flex-direction: row; 
    color: aliceblue;
    width: 95%;
    margin: 0 auto;
}
.main-23>h2{
    font-size: 4rem;
}
.para-div>p{
    font-size: 1.5rem;
    width: 50%;
    text-align: justify;
}
.main-23{
    width: 90%;
    margin: 0 auto ;
    z-index: 2;
}
.button-main{
    border-radius: 1.5rem;
    padding: 0.75rem;
    background-color: rgb(242, 96, 33);
    color: white;
    border-color: rgb(242, 96, 33);
    font-weight: 700;
    gap: 3rem;
}
.button-div{
    display: flex;
     gap: 4rem;
     align-items: center;
}
.button-div > a {
    text-decoration: none; 
    border-bottom: 2px solid rgb(242, 96, 33); 
    padding-bottom: 4px; 
    color: aliceblue;
}
.main-22{
    display: flex;
    gap: 2rem;
    position: absolute;
    z-index: 1;
    justify-content: flex-end;
    width: 90%;
    
}
.main-22>img{
    height: 35rem;
    opacity: 0.9;

}
.scroll-div{
    display: flex;
    padding-top: 10rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 90%;
    margin: 0 auto;

}
.try{
    width: 30rem;
}
.try2{
    width: 10rem;
}
.features-div{
    display: flex;
}
.cars-div{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    
}
.cars-div>img{
    padding-top: 7rem;
    width: 35em;
    align-items: center;
}
.cards-div{
    flex: 1;
    color: aliceblue;
}
.cards-div>h2{
    font-size: 4rem;
}
.cards-div>p{
    font-size: 1.5rem;
    font-weight: 300;
}
.cards-orange{
    color: rgb(242, 96, 33);
    font-weight: 400;

}
.cards-1{
    display: flex;
    gap: 1.5rem;
}
.cards-2{
    display: flex;
    gap: 1.5rem;
}
.cards-main{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.product-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-3{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
}

.product-heading{
    color: aliceblue;
}
.product-heading>h2{
    font-size: 2.5rem;
}
.product-heading>p{
    font-size: 1rem;
    font-weight: 400;
}
/* .product-button>img{
} */
.product-button{
    display: flex;
    
}
.card-container {
    display: flex;
    gap: 20px;
    width: 50%;
    margin: 0 auto;
    text-decoration: none;
}

.card {
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    text-decoration: none;
}

.card img {
    width: 100%;
    height: auto;
    object-fit: contain;
    
    
}

.card-content {
    padding: 15px;
}

.card-content h3 {
    margin: 0 0 10px;
    font-size: 1.2em;
    color: #333;
}

.card-content p {
    font-size: 0.9em;
    color: #777;
    margin: 0 0 15px;
}

.category {
    display: inline-block;
    font-size: 0.8em;
    color: #ff5722;
    background-color: #fbe9e7;
    padding: 5px 10px;
    border-radius: 5px;
}
.test-heading{
    padding-top: 10rem;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.test-heading>h2{
    font-size: 2.5rem;
}
.test-heading>p{
    font-size: 1rem;
    font-weight: 400;
    align-items: center;
}
.testi{
    display: flex;
    margin: 0 auto;
    width: 90%;
    align-items: center;
    justify-content: center;
}
.testi1>img , .testi2>img{
    width: 100%;
    height: auto;
}
.profile-card-container {
    display: flex;
    gap: 40px;
    justify-content: center ;
    margin: 0 auto;
    padding-top: 5rem;
}

.profile-card {
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
}

.profile-card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;

}

.profile-card-content h3 {
    margin: 0 0 10px;
    font-size: 1.2em;
    color: #333;
}

.profile-card-content h4 {
    margin: 0 0 10px;
    font-size: 1em;
    color: #777;
}

.profile-card-content p {
    font-size: 0.9em;
    color: #555;
    margin: 0;
}
.carBrands{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .carBrands-head{
        font-size: 2rem;
        color: white;
        font-weight: bolder;
        text-align: center;
    }
    .carBrands-subhead{
        font-size: 0.8rem;
        margin-top: -2rem;
        color: gray;
        font-weight: lighter;
    }
}
.carBrands-logo1{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    

    
}
.carBrands-logo2{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 0 auto;

    
}
.carBrands-logo3{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin: 0 auto;

    
}

.carBrand-but{
    display: flex;
    justify-content: center;

    button{
        margin-top: 2rem;
        font-size: 0.7rem;
        font-weight: lighter;
        background-color: rgba(242, 96, 33, 1);
        color: white;
        border: none;
        border-radius: 0.7rem;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}
.qa{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    margin: 0 auto;
}
.qa-1{
    display: flex;
    background-color: white;
    border-radius: 1rem;
    padding-left: 1rem;
    justify-content: space-between;
    padding-right: 1rem;
    align-items: center;
    img{
        height: 2.4rem;
    }
}
.answer-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    img{
        height: 2rem;
    }
}
.qa-2{
    display: flex;
    flex-direction: column;
    background-color: rgb(242, 96, 33);
    color: white;
    border-radius: 1rem;
    padding-left: 1rem;
    justify-content: space-between;
    padding-right: 1rem;
    align-items: center;
}
.qa-3>p{
    justify-content: center;
}
footer{
    border-radius: 2rem 2rem 0rem 0rem;
    margin-top: 5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    .footer-heading{
        font-size: 2rem;
        font-weight: bolder;
        color: white;
    }
    .footer-desc{
        margin-top: -2rem;
        font-size: 0.8rem;
        font-weight: lighter;
        color: white;
    }
    .footer-but{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 0.5rem;
        background-color: rgba(242, 96, 33, 1);
        color: white;
        border: none;
        border-radius: 0.6rem;
        font-size: 0.7rem;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;

        img{
            padding-right: 0.3rem;
            width: 1rem;
        }
    }
    .footer-img{
        padding-top: 2rem;
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items:end;
        img{
            width: 25rem;
        }
    }
}
@media screen and (max-width:1024px) {
    .features-div{
        flex-direction: column-reverse;
    }
    .scroll-div{
        flex-direction: column;
    }
    .testi{
        flex-direction: column;
        width: 100%;
    }
    .cards-1,.cards-2{
        display: flex;
        flex-direction: column;
    }
    nav{
        display: none;
    }
    .Home-main{
        width: 100%;
        margin: 0 auto;
    }
    .profile-card-container{
        flex-direction: column;
        align-items: center;
    }
    .card-container{
        flex-direction: column;
        width: 80%;
        align-items: center;
    }
    .cars-div>img{
        width: 100%;
    }
    .main-3{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .product-button{
        display: none;
    }
    .test-heading{
        padding-top: 2rem;
        align-items: flex-start;
    }
    .test-heading>p{
        align-items: flex-start;
    }
    .qa{
        width: 100%;
    }
    .main-23>h2{
        padding-top: 0rem;
        font-size: 4rem;
        text-align: justify;
        z-index: -2;
    }
    .para-div>p{
        padding-top: 2rem;
        font-size: 1.5rem;
        width: 100%;
        text-align: justify;
    }
    .main-2{
        width: 100%;
        z-index: -2;
    }
    .main-22>img{
        display: none;
    }
    .cards-1,.cards-2{
        width: 80%;
        margin: 0 auto;
    }
}