
body {
    overflow-x: hidden;
    background-color: black;
}

.product {
    background-color: black;
}

nav {
    background-image: url("/public/images/nav-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0rem 2rem;
    box-sizing: border-box;
    width: 100vw;
    z-index: 10;
}

.logo {
    width: 10rem;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.links a {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.mobile-dropdown {
    display: none;
    flex-direction: column;
    padding: 8rem 6rem 3rem 3rem;
    gap: 1rem;
    text-align: center;
    z-index: 8;
    position: fixed;
    width: 100vw;
    box-sizing: border-box;
    background-color: black;
    transform: translateY(-50vh);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.mobile-dropdown.open {
    display: flex;
    transform: translateY(0);
}

.mobile-dropdown a {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color:rgba(242, 96, 33, 1) ;
}

.mobile-menu {
    display: none;
    padding-top: 1rem;
}

.mobile-menu img {
    cursor: pointer;
}

.product-main{
    padding: 2rem 0rem 2rem 2rem;
    display: flex;

    .product-main1{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        flex: 1;
        color: white;

        .f1{
            font-size: 3.5rem;
            margin-bottom: -1rem;
            font-weight: 500;
        }
        .f2{
            font-size: 1.5rem;
            margin-bottom: 0.2rem;
        }
        .f3{
            font-weight: lighter;
            font-size: 1rem;
            margin-bottom: 0,8rem;
        }
        button{
            margin-top: 1rem;
            background-color: rgba(242, 96, 33, 1);
            border: none;
            border-radius: 1.5rem;
            padding: 1rem 1.5rem 1rem 1.5rem;
            color: white;
            cursor: pointer;
            font-weight: bolder;
        }
    }
    .product-main2{
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2,190px);

        .product-img{
            img{
                width: 180px;
            }
        }
        .product-img1{
            margin-left: -3rem;
            margin-top: -2rem;
            img{
                width: 180px;
            }
        }
    }
}
.pro-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        color: rgba(242, 96, 33, 1);
        font-size: 3rem;
        font-weight: bolder;
    }
}
.product-list{
    transition: transform 0.5s ease-in-out;
    padding-bottom: 2rem;
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap : 1rem;

}

.product-card{
    padding: 1rem;
    border: 1px solid rgba(242, 96, 33, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    img{
        width: 10rem;
    }
    .product-name{
        font-size: 1.2rem;
        font-weight: bolder;
        color: white;
    }
    .product-description{
        margin-top: -1rem;
        font-size: 0.8rem;
        font-weight: lighter;
        color: white;
    }
    .links{
        text-decoration: none;
        color:rgba(242, 96, 33, 1);
        font-size: 0.8rem;
    }
}

.product-card:hover{
    border: 1px solid rgba(242, 96, 33, 1);
}

.pro-det-container{
    display: flex;
    padding: 2rem;
    width: 90%;
    margin: 0 auto;
    gap : 5rem;

    .pro-det-img{
        border-radius: 1rem;
        background: linear-gradient(to bottom right,rgb(118, 118, 118),black);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(242, 96, 33, 1);
        margin-top: 7rem;
        flex: 1;
        padding: 1rem;
    }
    .pro-det-desc{
        flex: 1;
        margin-top: 7rem;

        .pro-det-head{
            font-size: 1.5rem;
            font-weight: bolder;
            color: white;
        }
        .pro-det-subhead{
            font-size: 1.2rem;
            font-weight: lighter;
            color: white;
        }
        .pro-det-btn{
            margin-top: 2rem;
            font-size: 0.7rem;
            font-weight: lighter;
            background-color: rgba(242, 96, 33, 1);
            color: white;
            border: none;
            border-radius: 0.7rem;
            padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        }
    }
}

.pro-det-top3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pro-det-top3-1{
        font-size: 2rem;
        color: white;
        font-weight: bolder;
    }
    .pro-det-top3-2{
        font-size: 1.2rem;
        margin-top: -2rem;
        color: white;
        font-weight: lighter;

        span{
            color: rgba(242, 96, 33, 1);
        }
    }
}

.features-cont{
    padding-top: 2rem;
    display: flex;
    width: 90%;
    margin: 0 auto;
    gap : 2rem;

    .features-cont1{
        height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        background-image: url("/public/images/features1.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        img{
            width: 25rem;
        }
    }
    .features-cont2{
        gap : 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .flex{
            display: flex;
            gap : 1rem;

            .fe3{
                background-color: white;
                height: 4rem;
                border: 1px solid gray;
                border-radius: 0.7rem;
                gap :1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                img{
                    width: 2rem;
                    filter: brightness(1) invert(0);
                }
    
                p{
                    font-size: 0.8rem;
                    color: black;
                    font-weight: lighter;
                }
            }
        }

        
    }   
}

.pagination-buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.hrline{
    color: rgb(94, 94, 94);
    width: 80%;
    margin: 0 auto;
    font-size: 0.1rem;
    border: none;
    background-color: gray;
    height: 1px;
}

.pagination-buttons button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    background-color: black;
    color: rgba(242, 96, 33, 1);
}

.pagination-buttons button:disabled {
    color: rgba(242, 96, 33, 0.3);
    cursor: not-allowed;
}
.footer-bg{
    background-image: url("../public/aboutBG.png");
    background-position: center;
    background-size: cover;
}

.carBrands{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    .carBrands-head{
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 2.5rem;
        color: white;
        font-weight: bolder;
    }
    .carBrands-subhead{
        font-size: 1.2rem;
        margin-top: -2rem;
        color: white;
        font-weight: lighter;
    }
}
.carBrands-logo1{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    img{
        width: 10rem;
    }

    
}
.carBrands-logo2{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    img{
        width: 10rem;
    }

    
}
.carBrands-logo3{
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin: 0 auto;
    img{
        width: 10rem;
    }

    
}

.carBrand-but{
    display: flex;
    justify-content: center;
    cursor: pointer;

    button{
        cursor: pointer;
        margin-top: 4rem;
        font-size: 0.7rem;
        font-weight: lighter;
        background-color: rgba(242, 96, 33, 1);
        color: white;
        border: none;
        border-radius: 0.7rem;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}
footer{
    border-radius: 2rem 2rem 0rem 0rem;
    margin-top: 5rem;
    /* background-image: url("/public/images/nav-background.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    .footer-heading{
        font-size: 2rem;
        font-weight: bolder;
        color: white;
    }
    .footer-desc{
        margin-top: -2rem;
        font-size: 0.8rem;
        font-weight: lighter;
        color: white;
    }
    .footer-but{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 0.5rem;
        background-color: rgba(242, 96, 33, 1);
        color: white;
        border: none;
        border-radius: 0.6rem;
        font-size: 0.7rem;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;

        img{
            padding-right: 0.3rem;
            width: 1rem;
        }
    }
    .footer-img{
        padding-top: 2rem;
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: flex-end;
        align-items:end;
        img{
            width: 25rem;
        }
    }
}

@media screen and (max-width: 512px) {
    .links {
        display: none;
    }

    .mobile-menu {
        display: block;
    }
    .product-main{
        /* margin-top: 5rem; */
        display: flex;
        flex-direction: column;
    }
    .product-list{
        width: 95%;
        margin: 0 auto;
        gap : 0.5rem;
        grid-template-columns: repeat(2,1fr);
    }

    .pro-det-container{
        display: flex;
        flex-direction: column;
    }
    .features-cont{
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }
    .carBrands-logo1{
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .carBrands-logo2{
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .carBrands-logo3{
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .pro-det-container{
        display: flex;
        padding: 0rem;
        width: 100%;
        margin: 0 auto;

        .pro-det-img{
            img{
                width: 15rem;
            }
        }
        .pro-det-desc{
            margin-top: -2rem;
        }
        
    }
    .product-main{
        padding: 2rem 0rem 2rem 2rem;
        display: flex;
    
        .product-main1{
            padding-top: 0rem;
        }
    }
}