/* General styling */
.body {
    background-color: black;
    padding: 2rem;
    z-index: 2;
  }
  
  .navMain {
    z-index: 4;
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    align-items: center;
    font-weight: bold;
    color: white;
    position: relative;
  }
  
  .navImg {
    flex: 2;
    cursor: pointer;
  }
  
  .navImg >a> img {
    height: 3em;
    
  }
  
  .navtext {
    display: flex;
    flex: 6;
    justify-content: flex-start;
    gap: 1rem;
  }
  
  .navtext > a {
    cursor: pointer;
    flex: 1;
    width: fit-content;
    text-decoration: none;
    color: white;

  }
  .navtext>a:hover{
    text-decoration: underline;
    text-decoration-color: #F26021;
    text-underline-offset: 1rem;
    text-decoration-thickness: 4px;
    
  }
  
  .navBut {
    display: flex;
    flex: 2;
    justify-content: center;
  }
  
  .navBut > button {
    width: 6rem;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 25px;
    background-color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Hamburger Menu Icon */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem;
    padding-right: 2rem;
  }
  
  .hamburger .line {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: 0.3s;
  }
  
  /* For screens smaller than 1024px */
  @media (max-width: 1024px) {
    .navtext {
      z-index: 3;
  
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      overflow: hidden;
      max-height: 0;  /* Initially collapsed */
      transition: max-height 0.5s ease-in-out; /* Smooth transition */
      background-color: rgba(0, 0, 0, 0.9);
      padding: 0; /* No padding when closed */
    }
  
    .navtext.showMenu {
      max-height: 300px;  /* Adjust based on how tall the menu should be */
      padding: 1rem;
      z-index: 3; /* Padding is restored */
    }
  
    .navtext > a {
      margin: 1rem 0;
      font-size: 1.2rem;
      opacity: 0; /* Start invisible */
      animation: fadeSlide 0.3s forwards ease-in-out;
    }

    .navtext.showMenu > a {
      animation-delay: 0.2s; /* Small delay for the text */
    }
  
    .hamburger {
      display: flex;
    }
    .navMain{
        width: 100%;
    }
  
    .navBut {
      display: none; /* You can adjust this depending on your design */
    }
  }
  
  /* Animation for sliding text in the menu */
  @keyframes fadeSlide {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  