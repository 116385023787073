.contact-div{
    overflow-x: hidden;

}
.sort{
    font-size: 5rem;
    font-weight: bold;

}
.f4{
    font-size: 1rem;
}
.contact-main{
    display: flex;
    flex-direction:row;
    color: white;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
}
.contact-head{
    font-size: 2rem;
    font-weight: 700;
}
.location,.mail,.phones{
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        align-items: center;
        justify-content: center;
    }
}
.form-container {

    background-color: #3a3f4a;
    padding: 30px;
    border-radius: 10px;
    width: 320px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-right: 5rem;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #2c2f37;
    color: #fff;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: #b1b3b7;
}

textarea {
    resize: none;
}

button {
    background-color: #ff5400;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #e14e00;
}
.social{
    display: flex;
    flex-direction: row;
    img{
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }
}
.error {
    color: red;
    font-size: 0.875rem; /* Adjust as needed */
    margin-top: 0.25rem; /* Adds space between input and error message */
    display: block; /* Ensures the error message stays below the input */
  }

@media screen and (max-width:1024px) {
    .contact-main{
        flex-direction: column;
    }
    .form-container{
        margin-top: 5rem;
        margin-right: 0rem;
    }
}